html {
  font-size: 62.5%;
  scroll-snap-type: y mandatory;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  color: inherit;
  text-decoration: none;
}
